import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
    <Helmet title='Hyperlocal | François Deguire' />
    <ProjectPage
      name='Hyperlocal'
      description={`
      Hyperlocal is an online marketplace that helps consumers find local 
      creators and artists, curated for their interests. It derives its 
      name from hyperlocal marketing, which aims to target consumers at the 
      scale of regions, cities or communities by showing them content relevant 
      to them. Hyperlocal works in a similar way by only showcasing products 
      and brands from the end user’s geographical region. Information about 
      the user is gathered in the three-step onboarding process that sets the 
      location, then lets him/her pick interests to curate products and finally 
      prompts the user to enter his basic info. All the content on the site 
      is then tailored to the user’s location and interests.
      `}
      meta={`
        Personal project<br>
        In 2020
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    hyperlocal1: file(relativePath: { eq: "hyperlocal/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hyperlocal2: file(relativePath: { eq: "hyperlocal/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hyperlocal3: file(relativePath: { eq: "hyperlocal/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hyperlocal4: file(relativePath: { eq: "hyperlocal/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hyperlocal5: file(relativePath: { eq: "hyperlocal/5.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
